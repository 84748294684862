<template>
  <div>
    <footer>
      <!-- <div class="slope slope--6"></div> -->
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
