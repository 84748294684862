var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section--2",attrs:{"id":"skills"}},[_c('div',{staticClass:"section__body"},[_vm._m(0),_c('div',{staticClass:"centered skill-grid"},[_c('div',{attrs:{"id":"wordcloud"}},[_c('vue-word-cloud',{attrs:{"words":[
              ['three.js', 1.5],
              ['JavaScript', 2],
              ['Vue.js', 2],
              ['WordPress', 2],
              ['Photoshop', 2.25],
              ['CSS/Sass', 2.25],
              ['Figma', 1.5],
              ['Illustrator', 2.25],
              ['InDesign', 1.5]
            ],"color":([, weight]) =>
                weight > 3
                  ? '#023183'
                  : weight > 2
                  ? '#4276CF'
                  : weight > 1
                  ? '#056299'
                  : '#04808F',"spacing":0.5,"font-family":"Jost, sans-serif"}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__title"},[_c('h3',{staticStyle:{"font-size":"36px"}},[_vm._v("Technical Skills")])])
}]

export { render, staticRenderFns }